import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Callback } from '../auth/components/callback';
import { Logout } from '../auth/components/logout';
import { LogoutCallback } from '../auth/components/logoutCallback';
import { SilentRenew } from '../auth/components/silentRenew';
import { SignInRedirect } from '../auth/components/signinRedirect';

import { PrivateRoute } from './PrivateRoute.jsx';

import Master from '../components/Master';

export const Routes = (
    <Switch>
        <Route exact={true} path='/signin-oidc-callback' component={Callback} />
        <Route exact={true} path='/signin' component={SignInRedirect} />
        <Route exact={true} path='/logout' component={Logout} />
        <Route exact={true} path='/logout/callback' component={LogoutCallback} />
        <Route exact={true} path='/silentrenew' component={SilentRenew} />
        <PrivateRoute path='/' component={Master} />
    </Switch>
);