import React from "react";
import { AuthConsumer } from "../providers/authProvider";
import LoadingIcon from '../../components/LoadingIcon';

export const Logout = () => (
    <AuthConsumer>
        {({ logout }) => {
            logout();
            return (<div className="loadingSplash"><LoadingIcon text={true} /></div>);
        }}
    </AuthConsumer>
);