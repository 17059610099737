import { v4 as uuidv4 } from 'uuid';
import { makeObservable, observable } from "mobx";
import ApiService from '../services/ApiService';

class RootStore {
    sessionId = uuidv4();
    status = '';

    /* Error */
    message = '';

    /* Master List */
    masterList = [];

    getMasterListAsync = async () => {
        try {
            const masterList = await this.apiService.get('Template', 'Master');
            if (masterList.length >= 0) {
                this.masterList = masterList;
            }
        } catch (error) {
            this.message = 'Error getting the list of Master templates.';
            this.status = 'Error';
        }
    };

    getMasterRowAsync = async (rowId) => {
        try {
            const masterRow = await this.apiService.get('Template', 'Master', [rowId]);
            console.log(masterRow);
        } catch (error) {
            this.message = 'Error getting the list of Master templates.';
            this.status = 'Error';
        }
    };

    deleteMasterRowAsync = async (rowId) => {
        try {
            const response = await this.apiService.delete('Template', 'Master', [rowId]);
            return response;
        } catch (error) {
            this.message = 'Error getting the list of Master templates.';
            this.status = 'Error';
        }
    };

    getMasterFileAsync = async (rowId) => {
        try {
            const masterFile = await this.apiService.getFile('Template', 'Master/Download', [rowId]);
            return masterFile;
        } catch (error) {
            this.message = 'Error getting the list of Master templates.';
            this.status = 'Error';
        }
    }

    uploadMasterFileAsync = async (file) => {
        try {
            const formData = new FormData();
            formData.append("masterFile", file);

            const response = await this.apiService.upload('Template', 'Master/Upload', formData);
            return response;
        } catch (error) {
            this.message = 'Error getting the list of Master templates.';
            this.status = 'Error';
        }
    }

    uploadMasterFileMultipleAsync = async (files) => {
        try {
            const formData = new FormData();
            files.forEach(file => {
                formData.append("masterFiles", file); 
            });

            const response = await this.apiService.upload('Template', 'Master/UploadMultiple', formData);
            return response;
        } catch (error) {
            this.message = 'Error getting the list of Master templates.';
            this.status = 'Error';
        }
    }

    constructor() {
        this.apiService = new ApiService();
        this.getMasterListAsync();
        makeObservable(this, {
            sessionId: observable,
            masterList: observable
        });
    }
}

export default new RootStore();
