import React, { Component } from 'react';

export default class Help extends Component {
  static displayName = Help.name;

  render () {
    return (
      <div className='help'>
        <h3>Help</h3>
        <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ullamcorper odio eu rhoncus vehicula. Vivamus eget pellentesque orci. Quisque convallis non eros et sollicitudin. Ut blandit est in lacus ornare, ut bibendum est porta. Quisque aliquam sodales nisl, eget tempor lacus gravida et. Phasellus scelerisque sodales lectus et auctor. Vivamus eu nisi diam. Nam eu auctor metus, at egestas enim. Interdum et malesuada fames ac ante ipsum primis in faucibus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec at cursus quam. Phasellus interdum arcu in luctus venenatis. In dignissim velit eu lectus vehicula commodo. Aliquam commodo diam sem, sed fermentum ex eleifend a.</div>
        <br />
        <div>In hac habitasse platea dictumst. Aliquam erat volutpat. Sed lacinia id enim et egestas. Pellentesque tempor elementum odio, sit amet condimentum dolor congue eu. Pellentesque in blandit justo. Curabitur faucibus, diam sit amet tristique finibus, odio lacus varius ipsum, at sodales ipsum tortor quis lorem. Integer non lorem eget purus porta tempor lacinia non elit. Praesent lacinia justo vel diam dapibus pulvinar. Maecenas bibendum odio tortor, at placerat erat dapibus commodo. Cras mollis tellus orci, eget eleifend lectus pulvinar ut. Quisque rutrum neque risus, id viverra velit aliquam non. Nam consequat varius tellus in varius. Sed varius molestie erat id dignissim. Donec sed arcu eu magna eleifend auctor eu vel nulla.</div>
        <br />
        <div>Donec vitae purus in velit convallis porta. Cras et justo sit amet sapien elementum lacinia non id mi. Vivamus sed magna tortor. Nunc egestas varius dolor, sit amet blandit purus sollicitudin at. Maecenas tincidunt erat eu velit consectetur, id gravida metus hendrerit. Praesent malesuada sollicitudin diam, sed finibus nisi tempus quis. Ut dapibus arcu id malesuada dictum. Aenean aliquam urna eu felis sodales, sed consectetur turpis consequat. Sed id porttitor justo.</div>
        <br />
        <div>Donec in dui sit amet lectus efficitur varius ut eu leo. Pellentesque sollicitudin condimentum est, sed dictum risus facilisis vel. Ut at accumsan turpis. Vivamus ac libero neque. Cras enim ipsum, maximus vel sodales sit amet, bibendum ac orci. Fusce semper porttitor ipsum hendrerit egestas. Nam vel risus velit. Etiam ut placerat ex. Donec sed tellus sem.</div>
        <br />
        <div>Morbi semper ante quis ante pellentesque, vitae lacinia quam blandit. Vestibulum tristique sem lectus, vitae volutpat augue ullamcorper congue. Donec ornare, tellus non convallis faucibus, massa lorem sodales ipsum, et tristique lectus nunc eget diam. Cras pharetra leo tempor leo tempor egestas. Phasellus quis mauris id libero imperdiet dictum. Nullam venenatis risus at pulvinar efficitur. Morbi vel urna et purus ultricies porttitor. Nullam mollis neque ut eros sagittis rutrum. Nunc ut velit elit. Nam convallis arcu nulla, quis posuere diam dignissim eget. Pellentesque gravida, ligula ac egestas molestie, lectus elit lacinia augue, quis suscipit velit eros vel neque. Etiam gravida diam vitae mi iaculis bibendum non sit amet odio. Donec luctus facilisis quam, quis vulputate dui hendrerit in. Vestibulum vehicula lacus vitae eros efficitur molestie.</div>
      </div>
    );
  }
}
