import { getCookie } from '../tools/Cookies';
import { apiUrl } from '../urls';
import { Between } from '../tools/Helpers';

class ApiService {

    get = async (controller, method, urlParams) => {
        const headers = new Headers();
        headers.append('Authorization', `Bearer ${getCookie('access_token')}`);

        const options = {
            method: 'GET',
            headers
        }
        let requestUrlParams = [apiUrl()];
        if (controller) requestUrlParams.push(controller);
        if (method) requestUrlParams.push(method);

        let requestUrl = requestUrlParams.join('/');
        if (urlParams) {
            urlParams.forEach(param => {
                requestUrl += `/${param}`;
            });
        }
        const request = new Request(requestUrl, options);
        const response = await fetch(request, { mode: 'cors', cache: 'no-cache' });
        if (response.ok) {
            return response.json();
        } else {
            if (Between(response.status, 400, 499)) {
                return 'Authentication error.  Please try logging out and back in, or contact us for assistance.';
            } else if (Between(response.status, 500, 599)) {
                return 'Server error.  Please contact us for assistance.';
            }
            return 'Generic Error.  Please contact us for assistance.';
        }
    }

    getZip = async (controller, method, urlParams) => {
        const headers = new Headers();
        headers.append('Authorization', `Bearer ${getCookie('access_token')}`);

        const options = {
            method: 'GET',
            headers
        }
        let requestUrlParams = [apiUrl()];
        if (controller) requestUrlParams.push(controller);
        if (method) requestUrlParams.push(method);

        let requestUrl = requestUrlParams.join('/');
        if (urlParams) {
            urlParams.forEach(param => {
                requestUrl += `/${param}`;
            });
        }
        const request = new Request(requestUrl, options);
        const response = await fetch(request, { mode: 'cors', cache: 'no-cache' });
        if (response.ok) {
            return response.blob();
        } else {
            if (Between(response.status, 400, 499)) {
                return 'Authentication error.  Please try logging out and back in, or contact us for assistance.';
            } else if (Between(response.status, 500, 599)) {
                return 'Server error.  Please contact us for assistance.';
            }
            return 'Generic Error.  Please contact us for assistance.';
        }
    }


    getFile = async (controller, method, urlParams) => {
        const headers = new Headers();
        headers.append('Authorization', `Bearer ${getCookie('access_token')}`);

        const options = {
            method: 'GET',
            headers
        }
        let requestUrlParams = [apiUrl()];
        if (controller) requestUrlParams.push(controller);
        if (method) requestUrlParams.push(method);

        let requestUrl = requestUrlParams.join('/');
        if (urlParams) {
            urlParams.forEach(param => {
                requestUrl += `/${param}`;
            });
        }
        const request = new Request(requestUrl, options);
        const response = await fetch(request, { mode: 'cors', cache: 'no-cache' });
        if (response.ok) {
            return response.blob();
        } else {
            if (Between(response.status, 400, 499)) {
                return 'Authentication error.  Please try logging out and back in, or contact us for assistance.';
            } else if (Between(response.status, 500, 599)) {
                return 'Server error.  Please contact us for assistance.';
            }
            return 'Generic Error.  Please contact us for assistance.';
        }
    }

    post = async (controller, method, urlParams, body) => {
        const headers = new Headers();
        headers.append('Authorization', `Bearer ${getCookie('access_token')}`);
        headers.append('Content-Type', 'application/json');

        var options = {
            method: 'POST',
            headers
        };
        if (body) {
            options.body = JSON.stringify(body);
        }
        let requestUrlParams = [apiUrl()];
        if (controller) requestUrlParams.push(controller);
        if (method) requestUrlParams.push(method);

        let requestUrl = requestUrlParams.join('/');
        if (urlParams) {
            urlParams.forEach(param => {
                requestUrl += `/${param}`;
            });
        }
        const request = new Request(requestUrl, options);
        const response = await fetch(request, { mode: 'cors' });
        if (response.ok) {
            return response;
        } else {
            if (Between(response.status, 400, 499)) {
                return 'Authentication error.  Please try logging out and back in, or contact us for assistance.';
            } else if (Between(response.status, 500, 599)) {
                return 'Server error.  Please contact us for assistance.';
            }
            return 'Generic Error.  Please contact us for assistance.';
        }
    }

    put = async (controller, method, urlParams, body) => {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', `Bearer ${getCookie('access_token')}`);

        var options = {
            method: 'PUT',
            headers
        };

        if (body) {
            options.body = JSON.stringify(body);
        }

        let requestUrlParams = [apiUrl()];
        if (controller) requestUrlParams.push(controller);
        if (method) requestUrlParams.push(method);

        let requestUrl = requestUrlParams.join('/');

        if (urlParams) {
            urlParams.forEach(param => {
                requestUrl += `/${param}`;
            });
        }
        const request = new Request(requestUrl, options);
        const response = await fetch(request, { mode: 'cors' });
        if (response.ok) {
            return response;
        } else {
            if (Between(response.status, 400, 499)) {
                return 'Authentication error.  Please try logging out and back in, or contact us for assistance.';
            } else if (Between(response.status, 500, 599)) {
                return 'Server error.  Please contact us for assistance.';
            }
            return 'Generic Error.  Please contact us for assistance.';
        }
    }

    putObj = async (controller, method, model) => {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', `Bearer ${getCookie('access_token')}`);

        var options = {
            method: 'PUT',
            headers,
            body: JSON.stringify(model)
        }
        let requestUrlParams = [apiUrl()];
        if (controller) requestUrlParams.push(controller);
        if (method) requestUrlParams.push(method);

        let requestUrl = requestUrlParams.join('/');
        const request = new Request(requestUrl, options);
        const response = await fetch(request, { mode: 'cors' });
        if (response.ok) {
            return response.json();
        } else {
            if (Between(response.status, 400, 499)) {
                return 'Authentication error.  Please try logging out and back in, or contact us for assistance.';
            } else if (Between(response.status, 500, 599)) {
                /* TODO: remove after api is fixed */
                return response.json();
                // return 'Server error.  Please contact us for assistance.';
            }
            return 'Generic Error.  Please contact us for assistance.';
        }
    }

    upload = async (controller, method, formData) => {
        const headers = new Headers();
        headers.append('Authorization', `Bearer ${getCookie('access_token')}`);

        var options = {
            method: 'POST',
            headers,
            body: formData
        }

        let requestUrlParams = [apiUrl()];
        if (controller) requestUrlParams.push(controller);
        if (method) requestUrlParams.push(method);

        let requestUrl = requestUrlParams.join('/');
        const request = new Request(requestUrl, options);
        const response = await fetch(request, { mode: 'cors' });
        if (response.ok) {
            if (response.status === 201)
                return true;
            return null;
        } else {
            if (Between(response.status, 400, 499)) {
                return `${response.status} error.  Please try logging out and back in, or contact us for assistance.`;
            } else if (Between(response.status, 500, 599)) {
                return 'Server error.  Please contact us for assistance.';
            }
            return 'Generic Error.  Please contact us for assistance.';
        }
    }

    patch = async (controller, method, model) => {
        const headers = new Headers()
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', `Bearer ${getCookie('access_token')}`);

        var options = {
            method: 'PATCH',
            headers,
            body: JSON.stringify(model)
        }
        let requestUrlParams = [apiUrl()];
        if (controller) requestUrlParams.push(controller);
        if (method) requestUrlParams.push(method);

        let requestUrl = requestUrlParams.join('/');
        const request = new Request(requestUrl, options);
        const response = await fetch(request);
        if (response.ok) {
            return response.json();
        } else {
            if (Between(response.status, 400, 499)) {
                return 'Authentication error.  Please try logging out and back in, or contact us for assistance.';
            } else if (Between(response.status, 500, 599)) {
                /* TODO: remove after api is fixed */
                return response.json();
                // return 'Server error.  Please contact us for assistance.';
            }
            return 'Generic Error.  Please contact us for assistance.';
        }
    }

    delete = async (controller, method, id, urlParams, body) => {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', `Bearer ${getCookie('access_token')}`);

        const options = {
            method: 'DELETE',
            headers
        };

        let requestUrl = `${apiUrl()}${controller ? `/${controller}` : ``}/${method}/${id}`;
        if (urlParams) {
            urlParams.forEach(param => {
                requestUrl += `/${param}`;
            });
        }

        if (body) {
            options.body = JSON.stringify(body);
        }

        const request = new Request(requestUrl, options);
        const response = await fetch(request, { mode: 'cors' });
        console.log(`%cresponse`, 'color: #FF0000');
        console.log(JSON.parse(JSON.stringify(response)));
        if (response.ok) {
            return response;
        } else {
            if (Between(response.status, 400, 499)) {
                return 'Authentication error.  Please try logging out and back in, or contact us for assistance.';
            } else if (Between(response.status, 500, 599)) {
                return 'Server error.  Please contact us for assistance.';
            }
            return 'Generic Error.  Please contact us for assistance.';
        }
    }
}

export default ApiService;