export function randomPicker(array) {
    return array[Math.floor(Math.random() * array.length)];
}

export function exists(obj) {
    return obj !== null && obj !== undefined;
}

export async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

export function Between(val, min, max) {
    return (val >= min && val <= max);
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else if (Array.isArray(source[key])) {
                if (!target[key]) {
                    Object.assign(target, { [key]: source[key] });
                } else {
                    let newItems = source[key]
                        .filter(item => target[key].map(item => item.Key).indexOf(item.Key) === -1);
                    if (newItems.length > 0) {
                        target[key] = target[key].concat(newItems);
                    }
                }
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeep(target, ...sources);
}

export function comparer(item1, item2, type) {
    if (type === 'length') {
        return item1.length - item2.length;
    } else if (type === 'num') {
        return item1 - item2;
    } else if (type === 'date') {
        return new Date(item1) - new Date(item2);
    } else if (type === 'string') {
        return item1 === null ? 1 : item2 === null ? -1 : item1.localeCompare(item2);
    } else {
        return item1 - item2;
    }
}

function randRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function formatDate(dateStr) {
    let date = new Date(dateStr);
    let month = `${date.getMonth() + 1}`.padStart(2, '0');
    let day = `${date.getDate()}`.padStart(2, '0');
    let year = `${date.getFullYear()}`.padStart(4, '0');
    return `${month}/${day}/${year}`;
}

export function capitalize(str) {
    return `${str.substring(0, 1).toUpperCase()}${str.substring(1)}`;
}