import React, { Component } from 'react';
import RootStore from '../stores/RootStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons'
import { observer, inject } from 'mobx-react';
import { FileDownloader } from '../tools/FileDownloader.jsx';
import { formatDate } from '../tools/Helpers'
import {
  Table,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

class MasterTable extends Component {
  static displayName = MasterTable.name;

  constructor(props) {
    super(props);

    this.state = {
      rowToRemove: null,
      showRemoveModal: false
    }
  }

  removeMasterRow = async () => {
    console.log('hallo');
    let result = await RootStore.deleteMasterRowAsync(this.state.rowToRemove);
    if (result.ok) {
      RootStore.getMasterListAsync();
      this.setState({ showRemoveModal: false });
    }
  }

  setShowRemoveModal(templateId, showRemoveModal) {
    this.setState({ rowToRemove: templateId, showRemoveModal });
  }

  downloadFile = async (rowId, fileName) => {
    let blob = await RootStore.getMasterFileAsync(rowId);
    if (blob) {
      FileDownloader(
        URL.createObjectURL(blob, {
          type: 'data:attachment/xlsx'
        }),
        `${fileName}`
        );
    }
  }

  render() {
    let rows = [];

    if (Array.isArray(this.props.RootStore.masterList)) {
      this.props.RootStore.masterList
        .slice()
        .sort((a, b) => {
          if (a.filePath.toLowerCase() < b.filePath.toLowerCase()) {
            return -1;
          }
          if (a.filePath.toLowerCase() > b.filePath.toLowerCase()) {
            return 1;
          }
          return 0;
        })
        .forEach(masterRow => {
          rows.push(<tr key={masterRow.templateId}>
            <td><a href="#" className="masterLink" onClick={() => this.downloadFile(masterRow.templateId, masterRow.filePath.toLowerCase())}>{masterRow.filePath}</a></td>
            <td className='centerCell'>{formatDate(masterRow.dateAdded)}</td>
            <td className='centerCell'>{masterRow.isFeed ? <FontAwesomeIcon icon={faCheck} /> : ""}</td>
            <td className='centerCell'>{masterRow.dataFieldCount}</td>
            <td>{masterRow.statusInfo}</td>
            <td className='centerCell'><input type="button" className="removeRow" value="-" onClick={() => this.setShowRemoveModal(masterRow.templateId, true)} /></td>
          </tr>)
        });
    }

    return (
      <>
        <Modal
          centered
          fullscreen=""
          scrollable
          size="md"
          isOpen={this.state.showRemoveModal}
        >
          <h3><FontAwesomeIcon icon={faEdit} /> Confirm removing a master template</h3>
          <ModalBody>
            {Array.isArray(this.props.RootStore.masterList) ? this.props.RootStore.masterList.find(masterRow => masterRow.templateId === this.state.rowToRemove)?.filePath : null}
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => this.setShowRemoveModal(false)}>
              Cancel
            </Button>
            {' '}
            <Button
              color="danger"
              onClick={() => this.removeMasterRow()}
            >
              Remove
            </Button>
          </ModalFooter>
        </Modal>
        <Table hover size="sm" responsive>
          {/* <colgroup>
            <col span="1" style={{ width: '45%' }} />
            <col span="1" style={{ width: '5%' }} />
            <col span="1" style={{ width: '5%' }} />
            <col span="1" style={{ width: '10%' }} />
            <col span="1" style={{ width: '30%' }} />
            <col span="1" style={{ width: '5%' }} />
          </colgroup> */}
          <thead>
            <tr>
              <th>File</th>
              <th className='centerCell'>Date Added</th>
              <th className='centerCell'>Is feed</th>
              <th className='centerCell'>Tag count</th>
              <th className='centerCell'>Status</th>
              <th className='centerCell'>Remove</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </Table>
      </>
    );
  }
};

export default inject('RootStore')(observer(MasterTable));