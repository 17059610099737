import { IDENTITY_CONFIG } from './utils/authConst';
import { UserManager, Log } from 'oidc-client';
import { getCookie, deleteCookie, setFreshCookie } from '../components/tools/Cookies';

export default class AuthService {
    UserManager;

    constructor() {
        const settings = {
            authority: IDENTITY_CONFIG.stsAuthority,
            client_id: IDENTITY_CONFIG.clientId,
            client_secret: IDENTITY_CONFIG.clientSecret,
            redirect_uri: `${IDENTITY_CONFIG.clientRoot}signin-oidc-callback`,
            silent_redirect_uri: `${IDENTITY_CONFIG.clientRoot}silentrenew`,
            post_logout_redirect_uri: `${IDENTITY_CONFIG.clientRoot}`,
            response_type: 'code',
            scope: IDENTITY_CONFIG.clientScope
        };

        this.UserManager = new UserManager(settings, );

        // Logger
        Log.logger = console;
        Log.level = Log.WARN;

        this.UserManager.events.addUserLoaded((user) => {
            setFreshCookie('access_token', user.access_token, 3);
            setFreshCookie('expires_at', user.expires_at, 3);

            if (window.location.href.indexOf('signin-oidc-callback') !== -1) {
                this.navigateToScreen();
            }
        });

        this.UserManager.events.addSilentRenewError((e) => {
            console.error('silent renew error', e.message);
        });

        this.UserManager.events.addAccessTokenExpired(() => {
            this.signinSilent();
        });
    }

    signinRedirectCallback = () => {
        this.UserManager.signinRedirectCallback().then(() => {
            '';
        }).catch(err => {
            console.error(err);
        });
    };


    getUser = async () => {
        const user = await this.UserManager.getUser();
        if (!user) {
            return await this.UserManager.signinRedirect();
        }
        return user;
    };

    parseJwt = (token) => {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    };

    signinRedirect = () => {
        localStorage.setItem('redirectUri', window.location.pathname);
        this.UserManager.signinRedirect({});
    };

    navigateToScreen = () => {
        window.location.replace('/');
    };


    isAuthenticated = () => {
        const expires_at = getCookie('expires_at');
        let result = false;
        if (expires_at) {
            const currentTime = Math.round(Date.now() / 1000);
            if (currentTime > expires_at) {
                this.signinSilent();
            }
            const access_token = getCookie('access_token');
            result = !!access_token;
        }
        return result;
    };

    signinSilent = () => {
        this.UserManager.signinSilent()
            .then((user) => {
                setFreshCookie('access_token', user.access_token, 3);
                setFreshCookie('expires_at', user.expires_at, 3);
            })
            .catch((err) => {
                /* TODO Figure out refresh tokens */
                console.error(err);
                this.signinRedirect();
            });
    };

    signinSilentCallback = () => {
        this.UserManager.signinSilentCallback();
    };

    createSigninRequest = () => {
        return this.UserManager.createSigninRequest();
    };

    logout = () => {
        this.UserManager.signoutRedirect({});
        deleteCookie('access_token');
        deleteCookie('expires_at');
        this.UserManager.clearStaleState();
    };

    signoutRedirectCallback = () => {
        this.UserManager.signoutRedirectCallback().then(() => {
            deleteCookie('access_token');
            deleteCookie('expires_at');
            localStorage.clear();
            window.location.replace(process.env.REACT_APP_PUBLIC_URL);
        });
        this.UserManager.clearStaleState();
    };
}