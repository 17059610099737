export function setCookie(c_name, value, exdays) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var c_value = escape(value) + ((exdays === null) ? "" : ";secure; SameSite=None; expires=" + exdate.toUTCString());
    document.cookie = c_name + "=" + c_value;
}

export function getCookie(c_name) {
    var i, x, y, ARRcookies = document.cookie.split(";");
    for (i = 0; i < ARRcookies.length; i++) {
        x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
        y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
        x = x.replace(/^\s+|\s+$/g, "");
        if (x === c_name) {
            return unescape(y);
        }
    }
}

export function deleteCookie(c_name) {
    document.cookie = c_name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function setFreshCookie(c_name, value, exdays) {
    if (getCookie(c_name) !== null) {
        deleteCookie(c_name);
    }
    setCookie(c_name, value, exdays);
}