import React from "react";
import { Route } from "react-router-dom";
import { AuthConsumer } from "../auth/providers/authProvider";
import LoadingIcon from '../components/LoadingIcon';

export const PrivateRoute = ({ component, ...rest }) => {
    const renderFn = (Component) => (props) => (
        <AuthConsumer>
            {({ isAuthenticated, signinRedirect, getUser }) => {
                console.log(isAuthenticated());
                if (!!Component && isAuthenticated()) {
                    return <Component {...props} user={getUser()} />;
                } else {
                    console.log(`%ctwo`, 'color: #FF0000');
                    signinRedirect();
                    return (<div className="loadingSplash"><LoadingIcon text={true} /></div>);

                }
            }}
        </AuthConsumer>
    );

    return <Route {...rest} render={renderFn(component)} />;
};