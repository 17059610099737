import React from "react";
import { AuthConsumer } from "../providers/authProvider";
import LoadingIcon from '../../components/LoadingIcon';

export const Callback = () => (
    <AuthConsumer>
        {({ signinRedirectCallback }) => {
            signinRedirectCallback();
            return (
                <div className="loadingSplash">
                    <LoadingIcon text={true}>
                        <div className="hidden">
                            {setTimeout(() => {
                                    var div = document.createElement('div');
                                    div.textContent = 'If you are not automatically redirected, click ';
                                    var a = document.createElement('a');
                                    var linkText = document.createTextNode("here.");
                                    a.appendChild(linkText);
                                    a.title = "Home";
                                    a.href = "/";
                                    div.appendChild(a);
                                    document.querySelector('.loadingDiv').appendChild(div);
                            }, 7000)}
                            {setTimeout(() => {
                                window.location.href = window.location.href.substring(0, window.location.href.indexOf('/', `https://`.length) + 1);
                            }, 3000)}
                        </div>
                    </LoadingIcon>
                </div>
            );
        }}
    </AuthConsumer>
);