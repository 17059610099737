import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Row, Col, Button } from 'reactstrap';
import RootStore from '../stores/RootStore.jsx';
import MasterTable from './MasterTable.js';
import Help from './Help';

class Master extends Component {
  static displayName = Master.name;

  constructor(props) {
    super(props); 
    
    this.state = {
      curFile: []
    }
  }

  onChange = (e) => {
    let curState = this.state;
    if (e.target.files.length > 0) {
      for (let idx = 0; idx < e.target.files.length; idx++) {
        curState.curFile.push(e.target.files[idx]);
      }

      this.setState({ curState });
    }
  }

  fileUpload = async () => {
    if (Array.isArray(this.state.curFile)) {
      if (this.state.curFile.length === 1) {
        let result = await RootStore.uploadMasterFileAsync(this.state.curFile[0]);
        if (result) {
          RootStore.getMasterListAsync();
        }
      } else {
        let result = await RootStore.uploadMasterFileMultipleAsync(this.state.curFile);
        if (result) {
          RootStore.getMasterListAsync();
        }  
      }
    }
    
    let node = ReactDOM.findDOMNode(this.refs[`fileUpload`]);
    if (node) node.value = '';

    this.setState({ curFile: [] });
  }

  render() {
    return (
      <div className='master'>
        <Row className='buttonRow'>
          <Col xs="12">
            <Button className='masterButton' onClick={this.fileUpload}>Add Master</Button>
            <input id="files" className="hidden" ref={`fileUpload`} type={'file'} multiple accept={`application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`} onChange={(e) => this.onChange(e)}></input>
            <label className="inputButton" htmlFor="files">Choose Files</label>
            <div className="fileList">
              {this.state.curFile.length > 0 ? this.state.curFile.map(file => file.name).join(', ') : "No File Selected"}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <MasterTable RootStore={RootStore} />
            </Col>
          {/* <Col xs="2" className="helpSection">
          </Col> */}
        </Row>        
        {/* <Help /> */}
      </div>
    );
  }
};

export default Master;