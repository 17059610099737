import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar, Spinner } from 'react-bootstrap';

const LoadingIcon = ({ text, progress, percent, fail, children }) => {
  return (
    <div className="loadingDiv">
      {/* <img className="loadingIcon" src={process.env.PUBLIC_URL + '/loading.svg'} /> */}
      {progress ? 
        percent ? 
        <ProgressBar className={`loadingBar`} now={percent} label={`${percent}%`} srOnly /> : <ProgressBar className={`loadingBar`} /> 
        : <Spinner animation="border" variant="light">
          <span className="sr-only">Loading...</span>
        </Spinner>}
      {/* {text ? <img className="dashboardText" src={process.env.PUBLIC_URL + '/dashboardLight.svg'} /> : null} */}
      {fail ? <p className='loadingError'>Your report is loading.  It can take a few seconds.</p> : null}
      {children}
    </div>
  );
}

LoadingIcon.propTypes = {
  text: PropTypes.bool,
  progress: PropTypes.bool,
  percent: PropTypes.number,
}

export default LoadingIcon;