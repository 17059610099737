import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { NavMenu } from './components/NavMenu';
import { Routes } from './routes/Routes'
import { AuthProvider } from "./auth/providers/authProvider";

import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <div>
        <NavMenu />
        <AuthProvider>
          <BrowserRouter children={Routes} basename={"/"} />
        </AuthProvider>
      </div>
    );
  }
}
